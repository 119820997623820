import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { ChatContext } from "Components";
import { FieldMessage } from "Components/Form";
import { Checkbox } from "Components/Form/Fields/Field/Checkbox";
import { firebase, EventContext } from "context";
import { Input, ButtonPrimary } from "notes";

export const ChatUsername = () => {
  const { username } = useContext(ChatContext);
  const { event } = useContext(EventContext);

  const [value, setValue] = useState("");
  const [checked, setChecked] = useState("");
  const [errors, setErrors] = useState({});
  const [updating, setUpdating] = useState(false);

  const setUsername = (username) => {
    firebase
      .functions()
      .httpsCallable("chat-username")({
        eventId: event.id,
        username,
        //token: cookies.token,
      })
      .then((data) => {
        if (data.data.success) {
          setUpdating(true);
        } else {
          setErrors({
            input: "Please try another username.",
          });
        }
      })
      .catch((error) => {
        setErrors({
          input: "Username error.",
        });
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const set = () => {
    if (!checked && !value) {
      setErrors({
        input: "Username is required.",
        checkbox: "Checkbox is required.",
      });
    } else if (!checked) {
      setErrors({
        checkbox: "Checkbox is required.",
      });
    } else if (!value) {
      setErrors({
        input: "Username is required.",
      });
    } else {
      setUsername(value);
    }
  };

  const handleChange = (value) => {
    setChecked(value);
    delete errors.checkbox;
    setErrors(errors);
  };

  const handleInputChange = (value) => {
    setValue(value);
    delete errors.input;
    setErrors(errors);
  };

  useEffect(() => {
    setValue(username);
  }, [username]);

  return (
    <ChatUsernameWrapper>
      <p className="Chat__Heading">Join the Conversation!</p>
      <p>
        <small>Create a display name below.</small>
      </p>

      <StyledInput
        value={value}
        error={!!errors["input"]}
        onChange={handleInputChange}
        placeholder={"Choose a display name..."}
      />
      <FieldMessage content={errors["input"]} active={!!errors["input"]} />

      <StyledCheckbox
        error={!!errors["checkbox"]}
        checked={checked}
        onChange={handleChange}
        title={ReactHtmlParser(`
          I agree to the Set.Live <a href="https://set.live/terms" target="_blank" rel="noopener noreferrer" onClick={chatAgreementHandler}>terms of use</a> and <a href="https://set.live/privacy" target="_blank" rel="noopener noreferrer" onClick={chatAgreementHandler}>privacy policy</a>.
        `)}
      />
      <FieldMessage
        content={errors["checkbox"]}
        active={!!errors["checkbox"]}
      />

      {updating ? (
        <StyledButton disabled>Saving</StyledButton>
      ) : (
        <StyledButton onClick={set}>Join Now!</StyledButton>
      )}
    </ChatUsernameWrapper>
  );
};

const ChatUsernameWrapper = styled.div`
  color: #ffffff;
  padding: 25px 15px;
  background: #1d1b1a;

  p {
    margin-top: 0;
    text-align: center;
  }

  .Chat__Heading {
    margin-bottom: 5px;
  }
`;

const StyledInput = styled(Input)`
  border-color: #ffffff;

  &:focus {
    border-color: gray;
    box-shadow: none;
  }
`;

const StyledButton = styled(ButtonPrimary)`
  background: #e6e9eb;
  color: #2f2f2f;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;

  :hover,
  :focus {
    background: #e6e9eb;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  color: #ffffff;
  margin-top: 25px;

  div:first-of-type {
    border-radius: 4px;
    border-color: #ffffff;
  }

  span {
    font-size: 12px;
  }

  a {
    color: #ffffff;
  }
`;
