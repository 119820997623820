import { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "context";
import { Header, Main, Footer } from "Layout";

export const PrimaryTemplate = ({
  registration,
  preCampaign,
  registered,
  voting,
  postCampaign,
  // header
  showHeader,
  // main
  heroContent,
  heading,
  subHeading,
  body,
  children,
  flexGrow,
  // site footer
  showFooter,
  setModalVisible,
}) => {
  const { event } = useContext(EventContext);

  return (
    <SiteContainer>
      {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>}

      <Header
        showHeader={showHeader}
        registration={registration}
        preCampaign={preCampaign}
        setModalVisible={setModalVisible}
      />

      <Main
        heroContent={heroContent}
        heading={heading}
        subHeading={subHeading}
        body={body}
        children={children}
        flexGrow={flexGrow}
        registration={registration}
        preCampaign={preCampaign}
        voting={voting}
      />

      <Footer
        showFooter={showFooter}
        registration={registration}
        preCampaign={preCampaign}
        registered={registered}
        voting={voting}
        postCampaign={postCampaign}
      />
    </SiteContainer>
  );
};

const SiteContainer = styled.div`
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  position: relative;
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 3px 10px;

  @media (max-width: 950px) {
    display: none;
  }
`;
