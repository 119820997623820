import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { faSmile } from "@fortawesome/free-regular-svg-icons";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  preCampaign,
  postCampaign,
}) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const { maxPolicy, brandDisclaimer, sweepsDisclaimer } = event.footer;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer>
            {(registration || postCampaign || registered) && (
              <>
                <SweepsDisclaimer
                  onClick={() =>
                    linkClickHandler(
                      "https://usmcmusic.com/corey-kent/rules",
                      "Rules",
                      "footer"
                    )
                  }
                >
                  {sweepsDisclaimer && ReactHtmlParser(sweepsDisclaimer)}
                  <CookieButton
                    onClick={() => {
                      if (window.Cookiebot) {
                        window.Cookiebot.renew();
                      }
                    }}
                  >
                    &nbsp;Click here to set Cookie Preferences.
                  </CookieButton>
                </SweepsDisclaimer>
              </>
            )}

            {preCampaign && maxPolicy && (
              <MaxPolicy
                onClick={() =>
                  linkClickHandler(
                    "https://www.max.live/",
                    "MAX homepage/privacy policy",
                    "footer"
                  )
                }
              >
                {ReactHtmlParser(maxPolicy)}
                <CookieButton
                  onClick={() => {
                    if (window.Cookiebot) {
                      window.Cookiebot.renew();
                    }
                  }}
                >
                  &nbsp;Click here to set Cookie Preferences.
                </CookieButton>
              </MaxPolicy>
            )}
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  z-index: 1;
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  position: relative;
  background-color: #991b20;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 50px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #991b20;
  }

  @media (max-width: 950px) {
    font-size: 0.5rem;
    padding: 10px 30px 30px;
  }
`;

const Link = styled.a`
  margin: 15px;
  align-self: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0 0 0 50px;
    align-self: start;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 234px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: unset;
    margin-top: 10px;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;
`;

const MaxPolicy = styled.div`
  margin: 0 auto;
  text-align: center;
  align-self: center;
`;

const BrandDisclaimer = styled.div``;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: #031689;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: inline;
  font-size: 10px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;
