import styled from "styled-components";
import { Modal as NotesModal } from "notes";

export const Modal = ({ open, setOpen, children }) => {
  return (
    <ModalBackground open={open}>
      <ModalWrapper size="large" open={open} setOpen={setOpen}>
        <ModalContainer>{children}</ModalContainer>
      </ModalWrapper>
    </ModalBackground>
  );
};

// ======== MODAL ======== //

const ModalBackground = styled.div`
  ${(props) =>
    props.open &&
    `
    background-color: rgb(0 0 0 / 75%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const ModalWrapper = styled(NotesModal)`
  padding: 60px 40px;
  min-height: unset;
  width: 90%;
  display: flex;
  flex: 1;
  background-color: transparent;
  line-height: 1.5;
  margin: 25px 0;

  & > svg {
    border: 1px solid #fff;
    border-radius: 25px;
    padding: 5px;
    margin-right: -30px;
    & > path {
      fill: #fff;
    }
  }

  & > svg:hover {
    background-color: #000000;

    & > path {
      fill: #ffffff;
    }
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: unset;
    margin: auto;
  }

  @media (max-width: 950px) {
    max-height: 65vh;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
