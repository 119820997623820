import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ShareSocial } from "react-share-social";
import { useMediaQuery } from "hooks/useMediaQuery";

const style = {
  background: "white",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 0px",
  margin: "0 auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
};

const styleMobile = {
  background: "white",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 0px",
  margin: "0 auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
};

const ShareCard = ({ url, title }) => {
  const [shareUrl, setShareUrl] = useState();
  const [shareTitle, setShareTitle] = useState();

  const mappings = [
    {
      a: "Loyalty",
      b: "Unselfishness",
      c: "Honesty",
      d: "Dependability",
    },
    {
      a: "Endurance",
      b: "Courage",
      c: "Initiative",
      d: "Decisiveness",
    },
    {
      a: "Justice",
      b: "Judgement",
      c: "Knowledge",
      d: "Tact",
    },
  ];

  useEffect(() => {
    url && setShareUrl(url);

    if (title) {
      const shareTitle = mappings.map((answer, i) => {
        return answer[title[i]];
      });
      setShareTitle(shareTitle);
    }
  }, [url, title]);

  return (
    <ShareWrapper>
      <img
        src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/marines-share-pg.png"
        alt="marines line up parade"
      />
      {shareTitle && (
        <h4>
          I just found out the traits I share with the Marines are{" "}
          {shareTitle?.[0]}, {shareTitle?.[1]}, and {shareTitle?.[2]}. How does
          your character compare to those found in the Marine Corps?
        </h4>
      )}
      {/* <p>{shareUrl ? shareUrl : "https://usmcmusic.com/corey-kent"} </p> */}
      <ShareSocial
        style={style}
        url={shareUrl ? shareUrl : "https://usmcmusic.com/corey-kent"}
        socialTypes={["facebook"]}
      />
    </ShareWrapper>
  );
};
export default ShareCard;

const ShareWrapper = styled.div`
  color: black;
  background-color: white;
  padding: 30px;

  h4 {
    margin-bottom: 0px;
  }

  @media (max-width: 950px) {
    max-width: 320px;
    padding: 10px;

    h4 {
      font-size: 0.9rem;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
`;
