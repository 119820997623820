const fieldMap = {
  street_number: "street_number",
  street_name: "route",
  unit: "subpremise",
  neighborhood: "neighborhood",
  CITY: "locality",
  county: "administrative_area_level_2",
  STATE: "administrative_area_level_1",
  country: "country",
  ZIP: "postal_code",
};

export const getAddressComponent = (place, types) => {
  const requestData = (types || []).reduce((acc, type) => {
    const component = place?.address_components?.find(({ types }) =>
      types.includes(fieldMap[type])
    );
    if (component) acc[type] = component;
    return acc;
  }, {});

  return requestData;
};
