import { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {
  EventContext,
  //AnalyticsContext,
  UserContext,
  firebase,
} from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  ButtonPrimary,
  Modal,
  LocalArtists,
  Media16By9,
  MediaVideo,
} from "Components";
import { fonts } from "theme/fonts";

export const Voting = ({ hasVoted = false }) => {
  const { event } = useContext(EventContext);
  //const { logClick } = useContext(AnalyticsContext);
  const { user } = useContext(UserContext);

  // Submits the voting for a user
  const submitUserVote = async () => {
    if (user) {
      const docId = `events/${process.env.REACT_APP_EVENT_ID}/votes/${user.uid}`;
      await firebase.firestore().doc(docId).set({ artist: selectedArtist });
    }
  };

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    desktop: artistImageDesktop,
    mobile: artistImageMobile,
  } = event.register.artistImage;
  let desktopImage = `${api}/o/${storageDirectory}%2F${artistImageDesktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${artistImageMobile}?alt=media`;

  let bgImg = `${api}/o/${storageDirectory}%2F${event.backgrounds[2]}?alt=media`;

  let backgroundObj = {
    image: bgImg,
    position: "right bottom 75px",
  };

  const setModalVisible = (bool) => {
    setOpen(bool);
    if (!bool) setVideo(false);
  };

  const handleVoteSubmission = async (event) => {
    event.preventDefault();
    if (selectedArtist) {
      submitUserVote().then((data) => {});
    }
  };

  const handleChange = (e) => {
    setSelectedArtist(e.target.value);
  };

  const { heading, formHeading, body, submitBtn } = event.register.voting[0];

  let votingContent = (
    <>
      <div className="div1">
        <VotingHeader>
          <HeadingMain>{ReactHtmlParser(heading)}</HeadingMain>
          <BodyMain>{ReactHtmlParser(body)}</BodyMain>
        </VotingHeader>
      </div>

      <div className="div2">
        <LocalArtists />
      </div>

      <div className="div3">
        <VotingForm onSubmit={handleVoteSubmission}>
          <FormHeading>{ReactHtmlParser(formHeading)}</FormHeading>
          {event.voting.localArtists.artists.map((artist, idx) => {
            return (
              <label key={idx}>
                <input
                  type="radio"
                  name="localArtist"
                  value={artist.artist}
                  onChange={handleChange}
                />{" "}
                {artist.artist}
              </label>
            );
          })}
          <SubmitButton>{ReactHtmlParser(submitBtn)}</SubmitButton>
        </VotingForm>
      </div>
    </>
  );

  if (hasVoted) {
    const { heading, body } = event.register.voting[1];

    backgroundObj = {
      image: desktopImage,
      position: "top center",
      size: "cover",
    };

    votingContent = (
      <>
        <div className="div1">
          <HeadingConfirmation>
            {ReactHtmlParser(heading[0])}
          </HeadingConfirmation>
          <BodyConfirmation>{ReactHtmlParser(body[0])}</BodyConfirmation>
        </div>

        <div className="div2">
          <ArtistImage>
            <img src={mobileImage} alt={event.meta.artistName} />
          </ArtistImage>
        </div>
      </>
    );
  }

  return (
    <Layout voting showHeader flexGrow showFooter background={backgroundObj}>
      {hasVoted && (
        <Curve>
          <Circle />
        </Curve>
      )}

      <Container>{votingContent}</Container>

      <Modal open={open} setOpen={setModalVisible}>
        <Media16By9>
          <MediaVideo
            src={video}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Media16By9>
      </Modal>
    </Layout>
  );
};

const Curve = styled.div`
  display: none;
  overflow: hidden;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const Circle = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    border-radius: 100%;
    background-color: #ffffff;
    width: 100vw;
    height: 100vw;
    min-height: 150%;
    margin-left: -107%;
  }

  @media only screen and ${(props) =>
      props.theme.maxMedia.large} and (min-height: 1200px) {
    margin-left: -89%;
  }
`;

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  flex: 1;
  justify-content: space-between;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 0px;
    z-index: 1;

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .div2 {
      grid-area: 1 / 2 / 3 / 3;
    }
    .div3 {
      grid-area: 2 / 1 / 3 / 2;
    }
  }
`;

const VotingHeader = styled.div`
  margin-bottom: 25px;
`;

const VotingForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-family: ${fonts.brand.secondary};
    margin: 2px 0;
  }

  input[type="radio"] {
    margin-right: 6px;
  }
`;

const FormHeading = styled.h2`
  text-transform: uppercase;
`;

/* === HEADING === */

const Heading = styled.h1`
  text-transform: uppercase;
`;

const HeadingMain = styled(FormHeading)``;
const HeadingConfirmation = styled(Heading)``;

/* === BODY === */

const Body = styled.p`
  font-family: ${fonts.brand.secondary};

  &:first-of-type {
    margin-top: 8px;
  }
`;

const BodyMain = styled(Body)``;
const BodyConfirmation = styled(Body)``;

const SubmitButton = styled(ButtonPrimary)`
  margin-top: 20px;
`;

const ArtistImage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 0 -15px -25px;

  img {
    width: 100%;
    display: block;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0;

    img {
      display: none;
    }
  }
`;
