import React, { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, UserContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import ShareCard from "Components/ShareCard";
import { PostCampaign } from "../PostCampaign";
import { QuizResults } from "Components/QuizResults/QuizResults";
import { useMediaQuery } from "hooks/useMediaQuery";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);
    const { user, userType, getUserRegistration, saveFormStep } = useContext(
      UserContext
    );

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;

    // quiz logic
    const [userData, setUserData] = useState();
    const [quizData, setQuizData] = useState();
    const [title, setTitle] = useState();

    const isMobile = useMediaQuery();

    useEffect(() => {
      getUserRegistration().then((data) => {
        setUserData(data);
      });
    }, [userType]);

    useEffect(() => {
      userData && setQuizData([userData.q1, userData.q2, userData.q3]);
    }, [userData]);

    let sweepsContentPart = formStep || 0;
    if (registered) {
      sweepsContentPart = event.register.sweeps.length - 1;
    }

    // logo
    const { brand: brandLogo } = event.logos;
    let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}?alt=media`;

    const { heading, subHeading, body, formHeading, formLegal, submit } = sweeps
      ? event.register.sweeps[sweepsContentPart]
      : event.register.postSweeps[sweepsContentPart];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event.register.ineligible;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;
    let videoBgDesktop = `${api}/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;
    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event.callToActions.brand.background;
    let brandBgDesktop = `${api}/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/${storageDirectory}%2F${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event.callToActions.brand.product;
    let productDesktop = `${api}/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
      image: brandSecondaryCTAImage,
      imageMobile: brandSecondaryCTAImageMobile,
    } = event.callToActions.brandSecondary;
    let imageCTADesktop = `${api}/${storageDirectory}%2F${brandSecondaryCTAImage}?alt=media`;
    let imageCTAMobile = `${api}/${storageDirectory}%2F${brandSecondaryCTAImageMobile}?alt=media`;

    const logoClickHandler = (link) => {
      logClick({ label: "Header Logo", url: link });
    };

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool) => {
      setOpen(bool);
    };
    const [resultCode, setResultCode] = useState();
    const collectResults = (obj) => {
      setResultCode(obj);
    };

    const [quizStep, setQuizStep] = useState();
    const getQuizStep = (step) => {
      setQuizStep(step);
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        showHeader={isMobile}
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          {registered && !postCampaign && !isMobile && (
            <BrandImage desktop={imageCTADesktop} mobile={imageCTADesktop} />
          )}
          {isMobile && postCampaign && (
            <ArtistContent isMobile={isMobile}>
              <Heading isMobile={isMobile}>Registration is closed</Heading>
              {/* <BrandImage desktop={productDesktop} mobile={productMobile} /> */}
            </ArtistContent>
          )}
          {(quizStep === 1 || !isMobile) && !registered && (
            <ArtistContent>
              {registered && !postCampaign ? (
                <BrandImage
                  desktop={imageCTADesktop}
                  mobile={imageCTADesktop}
                />
              ) : (
                !isMobile && (
                  <BrandImage desktop={productDesktop} mobile={productMobile} />
                )
              )}
              {isMobile && (quizStep === 1 || registered) && (
                <MobileWrapper isMobile={isMobile}>
                  {heading && (
                    <Heading unReg={isMobile}>
                      {ReactHtmlParser(heading)}
                    </Heading>
                  )}
                  {isMobile && (
                    <RegMobImage
                      src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/675-marines-corey-kent/heroImgMobile.png"
                      alt="marines line-up"
                    />
                  )}
                  {!isMobile && (
                    <BrandImage
                      desktop={productDesktop}
                      mobile={productMobile}
                    />
                  )}
                  {subHeading && (
                    <SubHeading unReg={isMobile}>
                      {ReactHtmlParser(subHeading)}
                    </SubHeading>
                  )}
                  {body && (
                    <Body unReg={isMobile}>{ReactHtmlParser(body)}</Body>
                  )}
                </MobileWrapper>
              )}
            </ArtistContent>
          )}
          <Content>
            {!isMobile && (
              <LogoBox>
                <Link
                  href={brandLogo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    linkClickHandler(
                      brandLogo.link,
                      "Marines Homepage",
                      "header"
                    )
                  }
                >
                  {brandLogoImg && <Logo src={brandLogoImg} />}
                </Link>
              </LogoBox>
            )}

            <ContainerPadding isMobile={isMobile && postCampaign}>
              {!postCampaign ? (
                <>
                  {!ineligible ? (
                    <>
                      {/* ===== CAMPAIGN INTRO ===== */}
                      {!isMobile && (quizStep === 1 || registered) && (
                        <>
                          {heading && (
                            <Heading reg={!registered}>
                              {ReactHtmlParser(heading)}
                            </Heading>
                          )}
                          {subHeading && (
                            <SubHeading>
                              {ReactHtmlParser(subHeading)}
                            </SubHeading>
                          )}
                          {body && <Body>{ReactHtmlParser(body)}</Body>}
                        </>
                      )}

                      {/* ===== SHARE COMP ===== */}
                      {registered && !postCampaign && (
                        <>
                          {isMobile && (
                            <MobileWrapper registered={true}>
                              {heading && (
                                <Heading unReg={isMobile} registered={isMobile}>
                                  {ReactHtmlParser(heading)}
                                </Heading>
                              )}
                              {isMobile && (
                                <MobileResultImage
                                  src={imageCTAMobile}
                                  alt="corey kent"
                                />
                              )}
                              {subHeading && (
                                <SubHeading unReg={isMobile}>
                                  {ReactHtmlParser(subHeading)}
                                </SubHeading>
                              )}
                              {body && (
                                <Body unReg={isMobile}>
                                  {ReactHtmlParser(body)}
                                </Body>
                              )}
                            </MobileWrapper>
                          )}
                          <Body results={true}>
                            {quizData?.map((data, i) => {
                              let answer = data;
                              return (
                                <Body unReg={isMobile}>
                                  {ReactHtmlParser(QuizResults[i][answer])}
                                </Body>
                              );
                            })}
                          </Body>
                          <div
                            style={
                              isMobile
                                ? {
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }
                                : {}
                            }
                          >
                            <ShareButton
                              onClick={() => {
                                setModalVisible(true);
                                linkClickHandler(
                                  "Share Quiz button",
                                  "Share Quiz",
                                  "internal"
                                );
                              }}
                            >
                              Share your results
                            </ShareButton>
                          </div>
                        </>
                      )}

                      {/* ===== REGISTRATION FORM ===== */}
                      {!registered && (
                        <RegistrationForm
                          formPart={formStep}
                          formHeading={formHeading}
                          formLegal={formLegal}
                          submitButton={submit}
                          setIneligible={setIneligible}
                          collectResults={collectResults}
                          getQuizStep={getQuizStep}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {/* ===== INELIGIBLE ===== */}
                      {ineligibleHeading && (
                        <Heading>{ReactHtmlParser(ineligibleHeading)}</Heading>
                      )}
                      {ineligibleBody && (
                        <Body>{ReactHtmlParser(ineligibleBody)}</Body>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* ===== POST-CAMPAIGN HEADINGS ===== */}
                  {postCampaignHeading && (
                    <PostWrapper>
                      <HeadingPostCampaign isMobile={isMobile}>
                        {ReactHtmlParser(postCampaignHeading)}
                      </HeadingPostCampaign>
                    </PostWrapper>
                  )}
                  {postCampaignBody && (
                    <BodyPostCampaign>
                      {ReactHtmlParser(postCampaignBody)}
                    </BodyPostCampaign>
                  )}
                </>
              )}
            </ContainerPadding>
            {postCampaign && !isMobile && (
              <PostCampaignImage
                src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/postCampaignImg.png"
                alt="marines line-up"
              />
            )}
            {(ineligible || postCampaign || registered || formStep > 0) && (
              <>
                {isMobile && !registered && (
                  <MobileResultImage
                    post={postCampaign}
                    src={imageCTAMobile}
                    alt="corey kent"
                  />
                )}
                <JoinContainer postCampaign={postCampaign}>
                  <ContainerPadding>
                    <Heading unReg={isMobile} registered={isMobile}>
                      THINK YOU’RE MARINE MATERIAL?
                    </Heading>
                    <Body unReg={isMobile} addOn={true}>
                      Ready to find out what it takes to become a Marine, and
                      what it means for our Nation to be one? Talking to a
                      Marine Recruiter can help determine not only if you’re
                      right for the Marine Corps, but if the Marine Corps is
                      right for you.
                    </Body>
                    <div
                      style={
                        isMobile
                          ? {
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }
                          : {}
                      }
                    >
                      <ShareButton
                        onClick={() => {
                          window.open(
                            "https://rmi.marines.com/request-information/WRRFY23MAXUSMCQUIZSITE"
                          );
                          linkClickHandler(
                            "https://rmi.marines.com/request-information/WRRFY23MAXUSMCQUIZSITE",
                            "Learn More",
                            "external"
                          );
                        }}
                        addOn={true}
                      >
                        Learn More
                      </ShareButton>
                    </div>
                  </ContainerPadding>
                </JoinContainer>
                {isMobile && postCampaign && (
                  <PostCampaignImage
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/675-marines-corey-kent/postCampaignImgMob.png"
                    alt="marines line-up"
                  />
                )}
              </>
            )}
          </Content>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <ShareCard url={userData?.url} title={quizData} />
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #991b20;

  padding: 0 0 10px 0;
  // gap: 50px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    padding: 0 0 20px 0;
  }
`;

const Content = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 43%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const ContainerPadding = styled.div`
  max-width: 450px;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  min-height: 100px;

  @media (max-width: 950px) {
    margin: 0 auto;
    padding: 0;
  }

  ${(props) =>
    props.isMobile && "height: 0px; min-height: 0px; margin-top: -20px"}
`;

const PostWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const MobileWrapper = styled.div`
  padding: 20px 0;
  ${(props) =>
    props.isMobile &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  ${(props) => props.registered && "padding: 20px 0px;"}
`;

const LogoBox = styled.div`
  padding: 0;
  display: flex;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 0;
    margin: 35px 0 45px -15px;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 950px) {
    margin: 2px 0 5px 0;
  }
`;

const MobileResultImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: -5px 0 20px;

  ${(props) =>
    props.post &&
    css`
      margin: 0px 0 -10px;
    `}
`;
const Link = styled.a`
  width: 100%;
  max-width: 80px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: 220px;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const MainHeading = styled.h1`
  border-bottom: 1px solid #707070;
  padding-bottom: 24px;

  & > span {
    display: block;
    font-size: 20px;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 30px;
    text-transform: uppercase;

    & > span {
      font-size: 24px;
    }
  }
`;

const Heading = styled.h2`
  text-transform: uppercase;
  font-size: min(2.4vw, 2rem);
  line-height: 110%;
  ${(props) =>
    props.reg && "border-bottom: 0.8px solid #EFC97B; padding-bottom: 25px;"}

  @media (max-width: 950px) {
    font-size: 1.7rem;
    margin-bottom: 10px;
    
    ${(props) => props.registered && "font-size: 1.5rem;"}
    
    ${(props) => props.isMobile && "padding: 30px; margin: 5px auto;"}
    
    ${(props) =>
      props.unReg &&
      css`
        padding: 0 20px 20px;
        max-width: 450px;
      `}
  }
`;
const SubHeading = styled.h3`
  font-size: min(1.4vw, 1.3rem);

  @media (max-width: 950px) {
    font-size: 1.1rem;
    margin-bottom: 1px;
    ${(props) =>
      props.unReg &&
      css`
        padding: 20px 25px;
        max-width: 450px;
      `}
  }
`;
const Body = styled.p`
  font-size: min(1vw, 1rem);
  font-weight: 100;

  @media (max-width: 950px) {
    font-size: 0.8rem;
    margin: 8px 0;
    ${(props) =>
      props.unReg &&
      css`
        padding: 0 25px;
        max-width: 450px;
      `}
  }

  span {
    font-family: "ExpresswayBd";
  }

  ${(props) =>
    props.results &&
    "border-top: 0.7px solid #efc97b; padding: 15px 0; margin-top: 25px "}

  ${(props) => props.addOn && "margin: -10px 0 5px 0;"}
`;
const HeadingPostCampaign = styled(Heading)`
  text-align: center;
  margin: auto;

  ${(props) => props.isMobile && "display: none;"}
`;
const BodyPostCampaign = styled(Body)``;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;
`;

const ShareButton = styled.button`
  color: #efc97b;
  width: 50%;
  padding: 15px;
  font-size: 0.8rem;
  text-transform: uppercase;
  border: 1px solid #efc97b;
  border-radius: 4px;
  background-color: transparent;
  margin: -10px 0 25px 0;

  ${(props) =>
    props.addOn &&
    css`
      margin: 20px 0 -5px;
      color: white;
      border: 1px solid white;
    `}

  @media (max-width: 950px) {
    width: 90%;
    margin: 0px auto 30px;
    ${(props) =>
      props.addOn &&
      css`
        margin: 20px 0 10px;
      `}
  }
`;

const JoinContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #001e2d;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0px;
  margin: 0;

  ${(props) => props.postCampaign && "margin: 0;"}
`;

const PostCampaignImage = styled.img`
  width: 100%;
  object-fit: cover;
`;
const RegMobImage = styled.img`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  object-fit: cover;
`;

const PoweredBy = styled.div`
  ${topBorder}
`;

const BrandSecondaryCTA = styled.div`
  ${topBorder}
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 950px) {
    /* min-height: 590px; */

    ${(props) => props.isMobile && ""}
  }
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: bottom;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }

  @media (max-width: 950px) {
    background-position: top;
    // margin-top: -20px;
  }
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 20px;
  margin: 0 0 40px;
  z-index: 1;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
`;
