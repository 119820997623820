import { useState, useRef, useReducer, useContext } from "react";
import styled from "styled-components";
import { reducer } from "Modules";
import { GridColumn, GridRow, Label } from "notes";
import { firebase, EventContext, AnalyticsContext } from "context";
import { Field, handleErrors } from "Components/Form";

export const PostShowSurvey = () => {
  const [surveyFeedback, setSurveyFeedback] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [surveySent, setSurveySent] = useState(false);
  const { event } = useContext(EventContext);
  const { logEvent } = useContext(AnalyticsContext);
  const surveyRef = useRef();

  const surveyFields = event.postShow.surveyFields;

  const scrollToTop = () => {
    surveyRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChange = (value, name) => {
    setSurveyFeedback(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(surveyFields, surveyFeedback, 0);

    if (!errorObject) {
      setSubmitting(true);
      try {
        const res = await firebase
          .firestore()
          .collection(`events/${event.id}/survey_responses`)
          .add({
            form: { ...surveyFeedback },
            //token: cookies.token,
          });

        if (res.id) {
          setSubmitting(false);
          setSurveySent(true);

          logEvent({ eventName: "post_show_survey_submission" });
        }
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
      scrollToTop();
    }
  };

  return (
    <ScrollBox ref={surveyRef}>
      <PostShowSurveyWrapper>
        {!surveySent ? (
          <>
            <SurveyHeading>We appreciate your feedback!</SurveyHeading>

            <Form>
              {surveyFields &&
                surveyFields
                  .sort((a, b) => a.order - b.order)
                  .map((field, index) => {
                    return (
                      <FieldContainer
                        fullSize={field.span === "full"}
                        key={index}
                      >
                        {field.type !== "optIn" && !!field.label && (
                          <FieldLabel>{field.label}</FieldLabel>
                        )}
                        <Field
                          field={field}
                          fieldCheck={
                            !!field.requires
                              ? surveyFeedback[field.requires]
                              : true
                          }
                          value={surveyFeedback[field.name]}
                          onChange={(value) => handleChange(value, field.name)}
                          error={{
                            status: errors[field.name],
                            message:
                              "This is a required field and cannot be blank.",
                          }}
                        />
                      </FieldContainer>
                    );
                  })}
            </Form>

            <ButtonContainer>
              {submitting ? (
                <SubmitButton disabled>Loading</SubmitButton>
              ) : (
                <SubmitButton onClick={() => handleSubmit()}>
                  Submit
                </SubmitButton>
              )}
            </ButtonContainer>
          </>
        ) : (
          <SurveyHeading>
            Thank you! Your feedback has been received.
          </SurveyHeading>
        )}
      </PostShowSurveyWrapper>
    </ScrollBox>
  );
};

const ScrollBox = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PostShowSurveyWrapper = styled.div`
  color: #ffffff;
  padding: 15px;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 20px 30px 0px;
    height: 100%;
  }
`;

const SurveyHeading = styled.h3``;

const ButtonContainer = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: column;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    position: sticky;
    bottom: 0;
    margin-left: -30px;
    margin-right: -30px;
    z-index: 2;
  }
`;

const SubmitButton = styled.button`
  background-color: #4580dd;
  color: #ffffff;
  font-weight: 300;
  transition: all ease 0.3s;
  outline: none;
  border: none;
  padding: 25px;
  &:hover,
  &:focus {
    background-color: #305da2;
  }
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 26px;
  }
`;

const Form = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 100%;
  margin-bottom: 25px;
  width: 100%;
`;

const FieldLabel = styled(Label)`
  color: #ffffff;
  margin-bottom: 8px;
`;
