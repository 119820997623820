import React, {
  useState,
  useReducer,
  useContext,
  useEffect,
  useMemo,
} from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import { Autocomplete } from "Components/Autocomplete";
//import { Autocomplete } from "notes";
import { debounce } from "lodash";
import ReactHtmlParser from "react-html-parser";
import { LoadScriptProps, useLoadScript } from "@react-google-maps/api";
import { getAddressComponent } from "./placesUtil";
import { reducer } from "Modules";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";
import Select from "react-select";
import { useMediaQuery } from "hooks/useMediaQuery";
import { usePlacesAutocomplete } from "./usePlacesAutocomplete";

export const googleGeocoder = async (placeId) => {
  // eslint-disable-next-line
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId });
  return results;
};

export const googlePrediction = async (input) => {
  console.log("input", input);
  // eslint-disable-next-line
  // if (google.maps.places) {

  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const { predictions } = await autocompleteService.getPlacePredictions(
    { input },
    (predictions, status) => {
      console.log("pred", predictions, status);
      if (
        // eslint-disable-next-line
        status != google.maps.places.PlacesServiceStatus.OK ||
        !predictions
      ) {
        return [];
      }
      return predictions;
    }
  );
  return predictions;
  // }
};

const libraries = ["places"];

export const RegistrationForm = ({
  registered,
  formPart,
  formHeading,
  formLegal,
  submitButton,
  setIneligible,
  collectResults,
  getQuizStep,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc, saveFormStep } = useContext(UserContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);
  const { logClick } = useContext(AnalyticsContext);
  const APIKey = process.env.REACT_APP_FIREBASE_APIKEY;
  const [values, setValues] = useState("");

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const isMobile = useMediaQuery();

  const fields = event.register.fields;
  //let visibleFormParts = [0];
  // console.log("reg", registration);
  //console.log("fields", fields);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    libraries: libraries,
  });

  const handleSearch = async (queryString) => {
    console.log("queryString", queryString);
    // setLoading(true);
    const results = await googlePrediction(queryString);
    console.log("results", results);

    return (
      results
        ?.filter((r) => !!r.structured_formatting.secondary_text)
        ?.map((c) => ({
          text: c.structured_formatting.main_text,
          placeId: c.place_id,
          // caption: c.structured_formatting.secondary_text,
          // value: c.place_id,
        })) || []
    );

    // setResults(places);
    //  setLoading(false);
  };

  const [userInfoMap, setUserInfoMap] = useState({
    FNAME: "",
    LNAME: "",
    DOB: "",
    EDU_LEVEL: "",
  });
  const [quizComplete, setQuizComplete] = useState(false);
  const [step, setStep] = useState(1);
  const [value, setValue] = useState();
  const [disable, setDisable] = useState(true);
  const [underAge, setUnderAge] = useState(false);
  const [displayLMessage, setDisplayLMessage] = useState(false);
  const [displayDateMessage, setDisplayDateMessage] = useState(false);
  const [displayFMessage, setDisplayFMessage] = useState(false);
  const [result, setResult] = useState({ q1: "", q2: "", q3: "", url: "" });

  let QuizContent;

  const handleQuizStep = () => {
    setTimeout(() => {
      setStep(step + 1);
      writeStepSubmission(step);
      renderQuiz();
      setValue("");
    }, 700);
  };

  const writeStepSubmission = async (step) => {
    let obj = {};
    obj[`step${step}`] = true;
    await saveFormStep(obj);
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  useEffect(() => {
    getQuizStep(step);
  }, [step]);

  result?.q3 && collectResults(result);

  const ageCheck = (e) => {
    if (!/^\d{0,2}\/?\d{0,4}$/.test(e.target.value)) {
      return;
    }
    let input = e.target.value;
    if (/^\d{3,}$/.test(input)) {
      input = input.match(new RegExp(".{1,2}", "g")).join("/");
    }

    let currentAge = input;
    setUserInfoMap({ ...userInfoMap, DOB: currentAge });
    let ageArray = currentAge.split("/");
    let month = parseInt(ageArray[0]);
    let year = parseInt(ageArray[1]);
    if (ageArray[1] && ageArray[1].length === 4) {
      if (
        month > 12 ||
        month < 1 ||
        year > 2020 ||
        year < 1960 ||
        ageArray[0].length !== 2
      ) {
        setDisplayDateMessage(true);
        return;
      } else {
        setDisplayDateMessage(false);
        const dateReformat = `${ageArray[1]}-${ageArray[0]}-01`;
        const d = DateTime.fromISO(dateReformat);
        const diff = d?.diffNow("years", {});
        const years = diff?.values?.years;

        if (years <= -17) {
          setUnderAge(false);
        } else if (years > -17) {
          setDisplayDateMessage(true);
          setUnderAge(true);
        }
      }
    }
  };

  const confirmAge = () => {
    if (!displayDateMessage && userInfoMap.DOB.length === 7) {
      setStep(step + 1);
      writeStepSubmission(step);
    }
  };

  useEffect(() => {
    if (underAge) {
      setTimeout(() => {
        window.open("https://www.marines.com/");
      }, 3000);
    }
  }, [underAge]);

  const renderQuiz = () => {
    if (step === 10) {
      QuizContent = (
        <ContainerDisplay show={step === 2 ? true : false} key="2">
          <NameInput
            type="text"
            displayDateMessage={displayDateMessage}
            value={userInfoMap.DOB.length === 0 ? value : userInfoMap.DOB}
            required
            onChange={(e) => {
              ageCheck(e);
              setValue(e.target.value);
            }}
            placeholder="DOB (MM/YYYY)*"
          ></NameInput>

          <MessageContainer>
            {underAge
              ? "The minimum required age is 17. Check out Marines website for more info. You will be transferred in 3 seconds"
              : "Must be 17 or older to participate"}
          </MessageContainer>

          <StartButton
            onClick={() => {
              confirmAge();
              linkClickHandler("Confirm age button", "Confirm age", "internal");
            }}
          >
            Confirm age
          </StartButton>
          <br />
          {isMobile && (
            <MessageContainer>
              Every Marine fights for a collective purpose– to protect our
              Nation’s future. The United States Marine Corps is a family that
              fights together, and all Marines share character traits that our
              Nation can count on. Which character traits do you share with
              Marines?
            </MessageContainer>
          )}
        </ContainerDisplay>
      );
    } else if (step === 1) {
      QuizContent = (
        <ContainerDisplay show={step === 4 ? true : false} key="4">
          <Gif
            src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/Question_1_B.gif"
            alt="marines"
            style={{ width: "100%", height: "auto" }}
          />
          <QuizWrapper>
            If your friends only had one word to describe you, what would it be?{" "}
          </QuizWrapper>
          <QuizWrapper selected={true}>(Select one)</QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="a"
              selected={result.q1 === "a" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q1: e.target.value });
              }}
            >
              Loyal
            </StyledButton>{" "}
            <StyledButton
              value="b"
              selected={result.q1 === "b" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q1: e.target.value });
              }}
            >
              Unselfish
            </StyledButton>
          </QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="c"
              selected={result.q1 === "c" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q1: e.target.value });
              }}
            >
              Honest
            </StyledButton>{" "}
            <StyledButton
              value="d"
              selected={result.q1 === "d" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q1: e.target.value });
              }}
            >
              Dependable
            </StyledButton>
          </QuizWrapper>
        </ContainerDisplay>
      );
    } else if (step === 2) {
      QuizContent = (
        <ContainerDisplay show={step === 5 ? true : false} key="5">
          <Gif
            src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/Question_2_B.gif"
            alt="marines"
            style={{ width: "100%", height: "auto" }}
          />
          <QuizWrapper>
            What attribute kicks in to help you overcome trying circumstances?
          </QuizWrapper>
          <QuizWrapper selected={true}>(Select one)</QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="a"
              selected={result.q2 === "a" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q2: e.target.value });
              }}
            >
              Resilience
            </StyledButton>{" "}
            <StyledButton
              value="b"
              selected={result.q2 === "b" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q2: e.target.value });
              }}
            >
              Courage
            </StyledButton>
          </QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="c"
              selected={result.q2 === "c" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q2: e.target.value });
              }}
            >
              First to <br />
              take action
            </StyledButton>{" "}
            <StyledButton
              value="d"
              selected={result.q2 === "d" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q2: e.target.value });
              }}
            >
              Quick decision-making
            </StyledButton>
          </QuizWrapper>
        </ContainerDisplay>
      );
    } else if (step === 3) {
      QuizContent = (
        <ContainerDisplay show={step === 6 ? true : false} key="6">
          <Gif
            src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/Question_3_A.gif"
            alt="marines"
            style={{ width: "100%", height: "auto" }}
          />
          <QuizWrapper>What makes you someone others can count on?</QuizWrapper>
          <QuizWrapper selected={true}>(Select one)</QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="a"
              selected={result.q3 === "a" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q3: e.target.value });
              }}
            >
              I do what’s right
            </StyledButton>{" "}
            <StyledButton
              value="b"
              selected={result.q3 === "b" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q3: e.target.value });
              }}
            >
              I have good judgement
            </StyledButton>
          </QuizWrapper>
          <QuizWrapper>
            <StyledButton
              value="c"
              selected={result.q3 === "c" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q3: e.target.value });
              }}
            >
              I make knowledgeable decisions
            </StyledButton>{" "}
            <StyledButton
              value="d"
              selected={result.q3 === "d" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                handleQuizStep();
                setResult({ ...result, q3: e.target.value });
              }}
            >
              I take appropriate action
            </StyledButton>
          </QuizWrapper>
        </ContainerDisplay>
      );
    } else if (step === 4) {
      setQuizComplete(true);
      setStep(step + 1);

      if (result.q3) {
        let resultCode = `${result.q1}-${result.q2}-${result.q3}`;
        resultCode = window.btoa(resultCode).replace(/=+$/, "");
        let url = `https://usmcmusic.com/corey-kent/share/${resultCode}`;
        setResult({ ...result, url: url });
      }
    }
  };
  renderQuiz();

  const [selected, setSelected] = useState("");
  const handleSelectChange = (result) => {
    console.log(result);
    setSelected(result.text);
  };

  const handleChange = (value, name) => {
    console.log("val", value);
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handlePlacesChange = async (value, name) => {
    console.log("value", value);
    setSelected(value.text);
    const placeResult = await googleGeocoder(value.placeId);
    setRegistration({
      ADDRESS1: value.text,
    });
    console.log("placeResultID", placeResult);
    const addressComps = getAddressComponent(placeResult[0], [
      "CITY",
      "STATE",
      "ZIP",
    ]);
    for (const key in addressComps) {
      if (addressComps?.[key]) {
        setRegistration({ [key]: addressComps[key].short_name });
      }
    }
    delete errors[name];
    setErrors(errors);
  };
  console.log("val", registration);

  useEffect(() => {
    setSubmitting(false);
  }, [submitButton]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errorObject = handleErrors(fields, registration, formPart);
    if (!errorObject) {
      setSubmitting(true);

      // formPart?
      let newFormPart = ++formPart;
      registration.formPart = newFormPart;
      writeStepSubmission(4);
      await writeToUserSubmissionDoc({
        ...registration,
        ...result,
      });
      logLeadSubmission({ newFormPart, registration });
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  const handleGoBack = () => {
    setStep(step - 1);
  };
  // if (!isLoaded) {
  //   return null;
  // }
  return (
    <FormContainer step={step === 0 ? true : false}>
      {step === 8 && (
        <Gif
          src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/583-marines-futuristic/Address.gif"
          alt="marines"
          style={{ width: "100%", height: "auto" }}
        />
      )}
      {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}
      {fields && (
        <RegForm onSubmit={handleSubmit}>
          {/* <>{QuizContent}</> */}
          {!quizComplete ? (
            <>{QuizContent}</>
          ) : (
            <Form>
              <FormTitle>
                <h2>ALMOST DONE</h2>
                <h4>Complete the form to get your results.</h4>
              </FormTitle>
              {isMobile && (
                <img
                  src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/675-marines-corey-kent/heroImgMobile.png"
                  alt="marines"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
              {fields
                ?.sort((a, b) => a.order - b.order)
                .map((field, index) => {
                  let mobileSize = "100%";
                  let desktopSize = "100%";
                  if (field.span === "half") desktopSize = "calc(45% - 4px)";

                  if (
                    field.span !== "full" &&
                    (field.spanMobile === "half" || !field.spanMobile)
                  )
                    mobileSize = "calc(50% - 4px)";

                  return (
                    <FieldContainer
                      sizeDesktop={desktopSize}
                      sizeMobile={mobileSize}
                      fullSize={field.span === "full"}
                      key={index}
                      order={field.order}
                    >
                      {field.type !== "optIn" && !!field.label && (
                        <Label>{field.label}</Label>
                      )}
                      {field.order === 5 ? (
                        <>
                          <Autocomplete
                            placeholder="Street Address Line 1*"
                            selected={selected}
                            onChange={(val) =>
                              handlePlacesChange(val, field.name)
                            }
                            //value={registration[field.name]}
                            search={handleSearch}
                            success={!errors.status && value}
                            error={{
                              status: errors[field.name],
                              message: errors[field.name]?.message,
                            }}
                          />
                        </>
                      ) : (
                        <Field
                          field={field}
                          value={registration[field.name]}
                          onChange={(value) => handleChange(value, field.name)}
                          error={{
                            status: errors[field.name],
                            message: errors[field.name]?.message,
                          }}
                        />
                      )}
                    </FieldContainer>
                  );
                })}

              {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}
            </Form>
          )}

          {submitting ? (
            <RegisterButton>LOADING</RegisterButton>
          ) : (
            quizComplete && (
              <RegisterButton
                type="submit"
                onClick={() =>
                  linkClickHandler(
                    "Submit Quiz button",
                    "Submit Quiz",
                    "internal"
                  )
                }
              >
                {submitButton ? submitButton : "Submit"}
              </RegisterButton>
            )
          )}
        </RegForm>
      )}
      {/* {step > 1 && step < 8 && (
        <BackButton onClick={handleGoBack}>← Back</BackButton>
      )} */}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 950px) {
    padding: 20px 25px;
  }
  ${(props) => props.step && "margin-top: 25px;"}
`;

const SelectList = styled(Select)`
  width: 100%;
  margin: 20px 0;
  z-index: 50;
  color: #00095b;
  font-size: 0.75rem;
  border-radius: 2px;

  & > div {
    border: 1px solid black;
    padding: 0;
    border-radius: 2px;
  }
  & > div > div > div > svg {
    fill: black;
  }
`;

const Gif = styled.img`
  margin-bottom: 30px;
`;

const BackButton = styled.div`
  font-size: 1.2rem;
  margin: 10% 0 5% 0;
  cursor: pointer;
`;

const ContainerDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  ${(props) => props.show && "animation: fadeIn 2s ease;"}
  ${(props) =>
    props.display &&
    "opacity: 1;"}

  @media (min-width: 450px) and (max-width: 950px) {
    ${(props) => props.display && "padding-top: 30px;"}
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const QuizWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  font-family: "ExpresswayBd";
  gap: 20px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;

  ${(props) =>
    props.selected &&
    "align-self: center; font-family: ExpresswayLite; justify-content: center; font-size: 0.9rem;"}
`;

const StyledButton = styled.button`
  background-color: #001e2d;
  border: 1px solid white;
  color: white;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  cursor: pointer;

  ${(props) => props.selected && "background-color: #DBAF4C; color: black;"}
`;

const MessageContainer = styled.div`
  display: block;
  margin-top: -5px;
  ${(props) => props.displayMessage && "display: block"}
`;

const ErrorMessage = styled.div`
  position: absolute;
  bottom: 53px;
  left: 0;
  //margin: -10px 0 15px;
  display: none;
  font-size: min(0.8vw, 0.9rem);
  ${(props) => props.displayMessage && "display: block"}
`;

const NameInput = styled.input`
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 10px 0 15px 10px;
  font-family: "ExpresswayReg";
  color: #00095b;
  font-size: 0.75rem;
  border: 1px solid #00095b;
  border-radius: 2px;

  ${(props) => props.displayLMessage && "background-color: #EFBBB0;"}
  ${(props) => props.displayFMessage && "background-color: #EFBBB0;"}
  ${(props) => props.displayDateMessage && "background-color: #EFBBB0;"}

  &::placeholder {
    color: black;
    font-family: "ExpresswayLite";
  }
`;

const StartButton = styled.button`
  padding: 10px;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  font-family: "ExpresswayLite";
  font-weight: 100;
  text-transform: uppercase;
`;

const FormTitle = styled.div`
  //text-transform: uppercase;
  // margin: 12px 0;
  font-weight: 600;

  h2,
  h4 {
    margin-bottom: 5px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 7px;
  row-gap: 24px;
  margin: 25px 0 20px;
  width: 100%;
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: 100%;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeMobile};
  ${(props) => props.order && `order: ${props.order};`}
  width: 100%;
  color: black;
`;

const Label = styled.span`
  color: #fff;
  font-size: 11px;
  margin-bottom: 5px;
`;

const FormLegal = styled.div`
  font-size: 11px;
  line-height: 1.5;
  margin: 7px 0;
  order: 99;
  color: #fff;

  a,
  a:visited {
    color: #fff;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  align-self: center;
  width: 60%;
  font-family: "ExpresswayLite";
`;
