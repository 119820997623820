import styled from "styled-components";

export * from "./SongPlayer";
export * from "./Video";
export * from "./VideoChat";
export * from "./VideoMeta";
export * from "./VideoModal";
export * from "./VideoPlayer";

export const Media16By9 = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  height: 50%;
  background-color: #191919;
`;

export const MediaVideo = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
