import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { Input, TextField } from "./Input.js";
import { FieldMessage } from "Components/Form/index.js";
//import { Input } from "Components/Form/Fields/Input";
//import { TextField } from "Components/Form/Fields/Field/Text";

const NO_RESULTS_TEXT = "No options found...";

export const Autocomplete =
  // forwardRef((props, ref) =>
  ({
    options,
    onChange,
    search,
    error,
    success,
    selected,
    Loading = LoadingDefault,
    Result = Item,
    ...props
  }) => {
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    console.log("errr", !!error.status);

    const handleChange = async (value, e) => {
      const newValue = e.currentTarget.value;
      if (newValue) {
        setShowOptions(true);
      }
      onChange({});
      setValue(newValue);
    };

    useEffect(() => {
      let cancelled = false;
      if (value) {
        (async () => {
          setLoading(true);
          const results = await search(value);
          if (!cancelled) {
            setLoading(false);
            setResults(results);
          }
        })();
      } else {
        setLoading(false);
        setResults([]);
        setShowOptions(false);
      }
      return () => {
        cancelled = true;
      };
    }, [value]);

    const onClick = (e, result) => {
      onChange(result);
      setValue("");
      setResults([]);
      setShowOptions(false);
    };

    let optionList;
    if (showOptions) {
      if (results.length) {
        optionList = results.map((result) => {
          const { text } = result;
          return (
            <Result
              key={text}
              onClick={(e) => onClick(e, result)}
              onKeyDown={(e) => {
                e.which === 13 && onClick(e, result);
              }}
              result={result}
              tabIndex={0}
              data-javelin-name={`change-location-select`}
              data-javelin-meta={JSON.stringify(result)}
            >
              {text}
            </Result>
          );
        });
      } else {
        optionList = (
          <Result result={{ text: NO_RESULTS_TEXT }}>{NO_RESULTS_TEXT}</Result>
        );
      }
    }
    return (
      <>
        <FieldWrapper>
          <Input
            InternalField={StyledInput}
            onChange={handleChange}
            value={value || selected}
            open={!!optionList}
            error={!!error.status}
            {...props}
          />
          {loading ? (
            <StyledDropdown>
              <Loading />
            </StyledDropdown>
          ) : (
            !!optionList && <StyledDropdown>{optionList}</StyledDropdown>
          )}
        </FieldWrapper>
        {error && (
          <FieldMessage
            content={error.message}
            type={error.status && "error"}
            style={{
              marginTop: "0",
              marginLeft: "8px",
            }}
          />
        )}
      </>
    );
  };
//   ({ ...props, ref })
// );

const FieldWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  label > & {
    margin-top: 9px;
  }
`;

const StyledInput = styled(TextField).attrs(() => ({ as: "input" }))`
  position: relative;
  z-index: 1;
  ${(props) =>
    props.open &&
    `border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: ${props.theme.colors.selectBottomBorder};
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 4;`};
  &:focus {
    border-color: ${(props) => props.theme.colors.border};
    ${(props) =>
      props.open &&
      `border-bottom-color: ${props.theme.colors.selectBottomBorder}`};
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  ${(props) =>
    props.disabled &&
    `background-color: ${props.theme.colors.buttonPrimaryBgDisabled};
    border-color: ${props.theme.colors.buttonPrimaryTextDisabled};
    pointer-events: none;`};
`;

const StyledDropdown = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
`;

const Item = styled.a`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  display: block;
  ${(props) => props.theme.fonts.default}
  font-weight: ${(props) => (props.selected ? 400 : 300)};
  line-height: 20px;
  position: relative;
  padding: 17px 12px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const LoadingDefault = () => <Item>Loading...</Item>;
