import { useContext } from "react";
import styled from "styled-components";
import { Duration } from "luxon";
import { EventContext, TimeContext } from "context";
import { fonts } from "theme/fonts";

export const Countdown = ({ label, days, hours, minutes, seconds }) => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);

  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  label = label ? label : "Show Starts In";
  days = days ? days : timeToStart.days;
  hours = hours ? hours : timeToStart.hours;
  minutes = minutes ? minutes : timeToStart.minutes;
  seconds = seconds ? seconds : timeToStart.seconds;

  return (
    <CountdownContainer>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Outline>
            <Increment>{days}</Increment>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Outline>
        )}

        <Outline>
          <Increment>{hours}</Increment>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Outline>

        <Outline>
          <Increment>{minutes}</Increment>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Outline>

        {days < 1 && (
          <Outline>
            <Increment>{seconds}</Increment>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  color: #ffffff;
`;

const CountdownTitle = styled.h3`
  font-size: clamp(32px, 9vw, 52px);
  line-height: 1;
  margin-bottom: 28px;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  border-radius: 20px;
  border: 2px solid #031689;
  padding: 0px 15px 15px;
  font-family: ${fonts.header};
`;

const Increment = styled.div`
  font-weight: 700;
  font-size: clamp(32px, 14vw, 90px);
  line-height: 1;
  padding: 10px 15px 15px;
  border-radius: 7px;
  width: 100%;
  text-align: center;
`;

const Label = styled.div`
  font-size: clamp(12px, 3vw, 21px);
`;
