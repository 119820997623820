import React, { forwardRef } from "react";
import styled from "styled-components";
//import { Icon } from "../../";

export const InputLeftContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 5;
`;
export const InputRightContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 5;
`;

export const InputContainer = styled.div`
  background-color: white;
  color: #00095b;
  border-radius: 3px;
  font-size: 14px;
  display: block;
  position: relative;
  z-index: 0;
  flex-grow: 1;
  ${(props) =>
    props.error &&
    `
  background-color: white;`}
`;

export const TextField = styled.input`
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  font-size: 15px;
  color: #00095b;
  border-color: #00095b;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  outline: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: ${(props) => (props.leftIcon ? "40px" : "12px")};
  padding-right: ${(props) => (props.rightIcon ? "40px" : "12px")};
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  &::placeholder {
  }
  &:focus {
  }
  &:disabled {
  }
  ${(props) =>
    props.error &&
    `border-color: #6d2621;
    border-width: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: ${props.leftIcon ? "39px" : "11px"};
    padding-right: ${props.rightIcon ? "39px" : "11px"};
    &:focus {
      border-color: #00095b;
      
    }
    `}
    border: ${(props) => (props.error ? "1px solid red" : "1px solid #00095b")};
`;

export const Input = forwardRef((props, ref) =>
  (({
    leftIcon,
    rightIcon,
    onChange,
    value,
    placeholder,
    error = false,
    disabled,
    style,
    placeholderStyle,
    InternalField = TextField,
    ...props
  }) => {
    const handleChange = (e) => {
      const {
        target: { value },
      } = e;
      return onChange(value, e);
    };
    return (
      <InputContainer style={style} error={error} disabled={disabled}>
        <InputPlaceholder
          visible={value}
          leftPadding={leftIcon}
          disabled={disabled}
          error={error}
          style={placeholderStyle}
        >
          {placeholder}
        </InputPlaceholder>
        <InputLeftContainer>{leftIcon}</InputLeftContainer>
        <InternalField
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          onChange={handleChange}
          value={value}
          error={error}
          disabled={disabled}
          {...props}
        />
        <InputRightContainer>{rightIcon}</InputRightContainer>
      </InputContainer>
    );
  })({ ...props, ref })
);

export const InputPlaceholder = styled.div`
  align-items: center;
  display: ${(props) => (props.visible ? "none" : "flex")};
  height: 40px;
  left: ${(props) => (props.leftPadding ? "40px" : "12px")};
  position: absolute;
  z-index: 1;
  color: ${(props) => (props.error ? "#6d2621" : "#00095b")};
`;
